/* Стиль для общего контейнера */
.easeKey-container {
  display: flex;
  flex-direction: column;
  height: 186px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* Стили для слайдеров внутри .easeKey-sliderContainer */
.easeKey-sliderContainer input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  margin: 15px 0;
  background: #ddd;
  border: none;
  outline: none;
  cursor: pointer;
}

.easeKey-sliderContainer input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2d8ceb;
  cursor: pointer;
}

.easeKey-sliderContainer input[type="range"]::-moz-range-thumb {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2d8ceb;
  cursor: pointer;
}

.easeKey-sliderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Стили для элемента span */
.easeKey-value {
  width: 30px;
  text-align: right;
  white-space: nowrap; /* предотвратит перенос строки */
  margin-left: 10px; /* добавит немного отступа между слайдером и значением */
}

.easeKey-icon {
  margin-right: 10px; /* добавьте отступ между иконкой и слайдером */
  font-size: 15px; /* установите нужный размер шрифта */
  opacity: 0.5;
}

.easeKey-icon,
.easeKey-value {
  width: 30px; /* фиксированная ширина, чтобы все иконки и текстовые значения были одного размера */
  text-align: center; /* выравнивание символов по центру */
  flex-shrink: 0; /* это предотвратит сжатие при изменении размера контейнера */
}

.easeKey-button__container {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.easeKey-button {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.easeKey-button span {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.easeKey-button span {
  opacity: 0.5;
  display: block;
  font-size: 0.8em;
}

.easeKey-button:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.easeKey-button:hover span {
  opacity: 1;
  color: #2d8ceb;
  background-color: rgba(0, 0, 0, 0.3);
}

.easeKey-second {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
