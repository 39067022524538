.button-card {
  width: 100%;
  height: 30px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  background: #232323;
  border: solid 1px #1d1d1d;
  display: flex;
  gap: 15px;
}

.button-group {
  width: 100%;
}

.button-card div:last-child {
  margin-left: auto;
}

.button-card:hover {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

.button-card__icon {
  margin-left: 10px;
  display: flex;
  width: 15px;
  font-size: 15px;
  opacity: 0.5;
}

.button-card__title {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-card > .button-card__title {
  transition: text-indent 0.4s ease-in-out;
}

.button-card:hover > .button-card__title {
  text-indent: 5px;
}

.button-card > .button-card__icon {
  transition: opacity 0.4s ease-in-out;
}

.button-card:hover > .button-card__icon {
  opacity: 1;
}

.button-card--disabled {
  opacity: 0.5;
  pointer-events: none; /* Делает элемент неактивным для событий мыши */
}

.button-card__tags {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  height: 100%;
  direction: rtl;
  position: relative; /* Добавляем */
  z-index: 2; /* Поднимаем над кнопкой */
  margin-left: auto; /* Сохраняем позиционирование справа */
}

.button-tag {
  width: 10px;
  height: 100%;
  cursor: pointer;
  opacity: 0.1;
  transition: all 0.4s ease-in-out;
  position: relative; /* Добавляем */
  z-index: 3; /* Поднимаем над контейнером тегов */
}

/* Добавляем hover эффект для тегов */
.button-tag:hover {
  opacity: 0.8; /* Делаем теги более видимыми при наведении */
}

.button-tag.active {
  opacity: 1;
}

/* Отключаем hover-эффекты кнопки при наведении на теги */
.button-card:has(.button-card__tags:hover) {
  background-color: #232323;
  color: inherit;
  border-bottom-color: #1d1d1d;
  fill: inherit;
}

.button-card:has(.button-card__tags:hover) > .button-card__title {
  text-indent: 0;
}

.button-card:has(.button-card__tags:hover) > .button-card__icon {
  opacity: 0.5;
}
