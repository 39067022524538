.grid__input-text{
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.grid__input-title{
  font-weight: 200;
  opacity: 0.5;
}
