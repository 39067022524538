.tags-container__tags {
  display: flex;
  justify-content: space-between;
  position: fixed;
  margin-top: 85px;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #232323;
  z-index: 1;
  box-sizing: border-box;
}

.tags-container__tag {
  width: 100%;
  height: 15px;
  cursor: pointer;
  opacity: 0.1;
  transition: all 0.3s ease-in-out;
}

.tags-container__tag:hover {
  opacity: 1;
}

.tags-container__tag.active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.tags-container__close {
  float: right;
  cursor: pointer;
  font-size: 24px;
}

.tags-container__tag-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.tags-container__tag-list-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
  gap: 10px;
}

.tags-container__main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags-container__tag-color {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.tags-container__tag-color:hover {
  opacity: 0.7;
}

.tags-container__delete-button {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.tags-container__delete-button:hover {
  opacity: 1;
}

.tags-container__buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.tags-container__button {
  display: flex;
  padding: 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  border: solid 1px #232323;
}

.tags-container__button:hover {
  opacity: 1;
  color: #2d8ceb;
}

.tags-container__add-new-tag {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.tags-container__add-new-tag:hover {
  opacity: 1;
  color: #2d8ceb;
}

.tags-container__edit-tags {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.tags-container__edit-tags:hover {
  opacity: 1;
}
