.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal-content {
  background: #161616;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 1280px;
  max-height: 100%;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}

.modal-close:hover {
  opacity: 1;
}
