.groups {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

/* Группы */
.group-container {
  border: solid 1px #1d1d1d;
  border-radius: 8px;
  width: 100%;
}

.group-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  background: #232323;
  color: #c5c5c5;
  transition: all 0.4s ease-in-out;
}

.group-header:hover,
.group-header.active {
  background-color: #1d1d1d;
  color: #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

.invalid:hover,
.invalid.active {
  color: red;
  fill: red;
}

.group-title {
  font-size: 16px;
}

.group-content {
  transition: max-height 0.3s ease-in-out;
}

.collapsed {
  max-height: 0;
  overflow: hidden;
}

.expanded {
  max-height: max-content;
  overflow-y: auto;
}

.group-body {
  padding: 16px;
}

.group-param {
  border-bottom: 1px solid #1d1d1d;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.group-param:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.group-title-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Расстояние между иконкой и названием */
}

.error-icon {
  color: red;
}
