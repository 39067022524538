.colors-schemes{
  margin: 20px 0;
  flex-direction: column;
  display: flex;
  gap: 10px;
  align-items: center;
}

.color-scheme-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.color-scheme-action{
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.color-scheme {
  display: flex;
  justify-content: center; /* Цвета располагаются горизонтально */
}

.color-block {
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-block:hover {
  opacity: 0.7;
}

.apply-span {
  display: block;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.1);
}

.color-edit{
  transition: all 0.4s ease-in-out;
  opacity:0;
}

.color-edit:hover{
  opacity:1
}

.apply-span:hover{
  opacity: 0.9;
}

.scheme-navigation {
  display: flex;
  justify-content: center;
  gap:15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.scheme-navigation-btn{
  padding: 10px;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border-radius: 6px;
}

.scheme-navigation-add{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.1);
}

.scheme-navigation-btn:hover {
  opacity: 0.9;
}
