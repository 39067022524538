.no-wrap{
  flex-wrap: nowrap;
}

.graphics__dropdown-text{
  display: flex;
  color: #c5c5c5;
  white-space: nowrap;
  background-color: #232323;
  padding: 5px 5px 5px 0;
  font-size: 0.9em;
}
