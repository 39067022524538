.collect-files-format {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
  color: #2d8ceb;
  border-radius: 5px;
}

.collect-files-format:hover {
  color: white;
}
