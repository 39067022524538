.mogrt-group__button {
  /* Ваши стили для кнопки Show Items */
}

.mogrt-group__popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #232323;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* This enables vertical scrolling */
}

.mogrt__group-sliders {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.mogrt-group__item {
  display: flex;
  gap: 10px;
  align-self: center;
  padding: 10px 20px;
  opacity: 1;
  color: #c5c5c5;
  background-color: #232323;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 1px solid #161616;
}

.mogrt-group__header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-width: 50%;
  max-width: 50%;
}

.mogrt-group__item-group {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: default;
  color: #c5c5c5;
  transition: all 0.4s ease-in-out;
}

.mogrt-group__item--dragging {
  opacity: 0.5; /* Прозрачность при перетаскивании */
}

.mogrt-group__nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  background-color: #232323;
}

.mogrt-group__nav-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  padding: 10px 0;
  color: #c5c5c5;
  min-width: 25px;
  cursor: pointer;
  border-bottom: solid 1px #232323;
  justify-content: center;
  background-color: #161616;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 3px;
  text-align: center;
}

.mogrt-group__nav-item:hover {
  background-color: #232323;
  color: #2d8ceb;
  border-bottom: solid 1px #2d8ceb;
}

.mogrt-group__item-name {
  margin-left: 30px;
}

.mogrt-group__item-icon {
  display: flex;
  align-self: center;
}

.mogrt-group__item-slider {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 3px;
}

/* Стили для слайдеров внутри .easeKey-sliderContainer */
.mogrt__group-sliders input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  margin: 0;
  background: #ddd;
  border: none;
  outline: none;
}

.mogrt__group-sliders input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2d8ceb;
  cursor: pointer;
}

.mogrt__group-sliders input[type="range"]::-moz-range-thumb {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2d8ceb;
  cursor: pointer;
}

.item-in-group {
  width: 100%;
  border: 1px solid #161616;
}

.item__input-slider {
  color: #2d8ceb;
  padding: 5px;
  width: 30px;
  border: solid 1px #232323;
  background-color: #232323;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.item__input-slider:focus {
  border: solid 1px #2d8ceb;
  outline: none;
}

.item__input {
  color: #c5c5c5;
  padding: 5px;
  width: 100%;
  border: solid 1px #161616;
  background-color: #232323;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.item__input:focus {
  border: solid 1px #2d8ceb;
  outline: none;
}

.item__color {
  width: 100%;
}

.group {
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border: 1px solid #161616;
  position: relative;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.group__items {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.group.selected {
  background-color: #161616;
}

.group__input {
  color: #c5c5c5;
  padding: 5px;
  width: 50%;
  border: solid 1px #161616;
  background-color: #232323;
}

.group__input:focus {
  border: solid 1px #2d8ceb;
  outline: none;
}

.group__input_item {
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.group__input_item :last-child {
  margin-left: auto;
}

.group__input-delete {
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.group__input-delete:hover {
  color: #2d8ceb;
}

.group__input_toggle {
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.group__input_toggle:hover {
  color: #2d8ceb;
}

.group__input_toggle.open i {
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}

.group__input_toggle i {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.input-error {
  border: 1px solid red;
  /* другие стили, чтобы подсветить невалидное поле */
}

.input-error:focus {
  border: 1px solid red;
}

/* .mogrt-group__item:hover {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
} */
