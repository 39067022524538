.auto__beat {
  margin-top: 10px;
}

.auto__beat_span {
  width: 30px;
  margin-right: 10px; /* добавьте отступ между иконкой и слайдером */
  opacity: 0.5;
  font-size: 13px;
  text-align: center;  /* выравнивание символов по центру */
  flex-shrink: 0;
}
