/* TelegramLink.css */
.telegram-link-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.telegram-link-text,
.telegram-link-input {
  min-height: 25px;
  min-width: 250px;
  max-width: 250px;
  font-size: 16px; /* Размер шрифта */
  text-align: center;
}

.telegram-link-text {
  padding: 5px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  margin: 0;
}

.telegram-link-text:hover {
  opacity: 0.7;
}

.telegram-link-input {
  transition: all 0.4s ease;
  outline: none; /* Убираем контур при фокусе */
  background-color: #161616;
  border: none;
  color: #c5c5c5;
  margin-top: 6px;
  border: solid 1px rgba(1,1,1,1);
}

.telegram-link-input:hover {
  border: solid 1px #3456f3;
  outline: none;
}

.telegram-link-input:focus {
  border: solid 1px #3456f3;
  outline: none;
}

.telegram-link__span {
}

.telegram-link__request {
  cursor: pointer; /* чтобы пользователь видел, что это кликабельный элемент */
  background-color: #007bff; /* красный цвет, например, для кнопки выхода */
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  margin-top: 16px; /* отступ сверху для отделения от других элементов */
  transition: background-color 0.3s;
  transition: opacity 0.5s ease;
}
.telegram-link__request.inactive {
  /* Стили для неактивного состояния */
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
