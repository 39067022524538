.anchorAlignContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 186px;
}

.anchorAlignContainer__selectors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; /* Добавлен отступ между селекторами и иконками */
  gap: 5px;
}

.anchorAlignContainer__selector {
  flex: 1; /* Растянуть каждый селектор равномерно */
  cursor: pointer;
  padding: 10px;
  text-align: center; /* Если у вас есть текст внутри селектора, это убедится, что он отцентрован */
}

.anchorAlignContainer__selector i {
  font-size: 18px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.anchorAlignContainer__selector:hover i {
  color: #007bff;
  opacity: 1;
}

.anchorAlignContainer__selector.active i {
  color: #007bff;
  opacity: 1;
}

.anchorAlignContainer__iconsGrid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 25px;
  justify-content: center;
  font-size: 18px;
  text-align: center;
}

.anchorAlignContainer__icon {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}

.anchorAlignContainer__icon:hover {
  cursor: pointer;
  color: #007bff;
}

.anchorAlignContainer__icon.active {
  cursor: pointer;
  color: #007bff;
}

.anchorAlign__iconsGrid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 14px;
  justify-content: center;
  font-size: 14px;
}
