.footer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  margin: 0;
  overflow: hidden;
  background: #232323;
  z-index: 1;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
}

.footer__icon {
  max-height: 16px;
  margin: 0;
  padding: 0;
}

.footer__button {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 30px;
  font-size: 14px;
  color: #c5c5c5;
  background: #232323;
  margin: 0 auto;
  padding: 10px 0;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 500;
  text-align: center;
  border: solid 1px #1d1d1d;
  transition: all 0.4s ease-in-out;
  gap:10px;
  cursor: pointer;
  opacity:0.5;
}

.footer__button:hover{
  opacity:1;
}
