.buttons-card__list {
  list-style: none;
  padding: 0 0 45px;
  margin: 0;
  display: grid;
  width: 100%;
  align-items: center;
  align-content: center;
}

.buttons-card__grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(80px, 1fr)
  ); /* Минимальная ширина для кнопки установлена в 180px. */
  padding: 10px;
  width: 100%;
}

.buttons-card {
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); /* это центрирует сам fixed элемент */
  height: 100vh; /* или max-height: 100vh */
  overflow-y: auto;
}

.buttons-card__checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 10px 0 10px 17px;
  width: 100%;
}

.add-button {
  cursor: pointer;
  min-width: 75px;
  height: 75px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 25px;
}

.sliderContainer {
  position: fixed;
  display: flex; /* убедимся, что блок занимает всю ширину */
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
  background-color: #232323;
  left: 50%;
  transform: translateX(-50%); /* это центрирует сам fixed элемент */
  z-index: 1;
}

.sliderContainer-row {
  display: flex;
}

.slider {
  display: block; /* убедимся, что блок занимает всю ширину */
  width: 100%; /* убедимся, что блок занимает всю ширину */
  box-sizing: border-box;
}

.sliderButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sliderButton:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.sliderButton--left {
  left: -45px; /* или другое значение для смещения от края */
}

.sliderButton--right {
  right: -45px; /* или другое значение для смещения от края */
}

.additional__navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); /* это центрирует сам fixed элемент */
  z-index: 1;
}

.additional__navigation_item {
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  padding: 5px 0;
  color: rgba(197, 197, 197, 0.5);
  min-width: 25px;
  cursor: pointer;
  justify-content: center;
  font-weight: 900;
  background-color: #232323;
  border: solid 1px #1d1d1d;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: #232323;
}

.additional__navigation_item:hover {
  background: #1f1f1f;
  color: #2d8ceb;
  border-bottom: solid 1px #2d8ceb;
  opacity: 1;
}

.additional__navigation_item--active {
  background: #1f1f1f;
  color: #2d8ceb;
  border-bottom: solid 1px #2d8ceb;
  opacity: 1;
}
