.placeholder-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
}

.placeholder-icon{
  font-size: 15px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.placeholder-icon:hover{
  cursor: pointer;
  color: #007BFF;
  opacity: 1;
}
