.email-link__span {
  cursor: pointer;
}

.email-send__code {
  cursor: pointer; /* чтобы пользователь видел, что это кликабельный элемент */
  background-color: #007bff; /* красный цвет, например, для кнопки выхода */
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  margin-top: 16px; /* отступ сверху для отделения от других элементов */
  transition: background-color 0.3s;
  transition: opacity 0.5s ease;

  /* Хорошо бы добавить эффекты наведения и нажатия */
}

.email-send__code:hover {
  opacity: 0.9;
}

.email-profile__logout{
  font-size: 10px;
}

.email-send__code-disabled{
  margin-top: 10px;
  color: #c5c5c5;
  opacity: 0.5
}

.email__logout{
  padding: 0;
  background-color: transparent;
  opacity: 0.3
}
