.validator-popup {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #232323;
  width: 100%;
  max-height: 100vh; */
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  /* min-height: 100vh; */
}

/* Заголовок */
.header {
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.meta {
  font-size: 14px;
}

/* Контент */
.content {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background: #232323;
  overflow-x: hidden;
  min-width: 100%;
}

/* Загрузка и ошибки */
.loading {
  padding: 20px;
  font-size: 16px;
}

.error {
  padding: 20px;
  color: red;
  font-size: 16px;
}
