.composition-settings__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  padding: 0 10px;
  gap: 5px;
  box-sizing: border-box;
}

.composition-settings__dropdown {
  display: flex;
  flex-direction: column;
  color: #c5c5c5;
  background-color: #161616;
  width: 100%;
}

.composition-settings__dropdown select {
  cursor: pointer;
  color: #c5c5c5;
  padding: 5px;
  width: 100%;
  border: solid 1px #232323;
  transition: all 0.3s ease-in-out;
  background-color: #161616;
  appearance: none; /* Убирает стандартные стили браузера для <select> */
}

.composition-settings__dropdown select:hover {
  border: solid 1px #3456f3;
  outline: none;
}

.composition-settings__dropdown select:focus {
  border: solid 1px #3456f3;
  outline: none;
}

.composition-settings__option-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  min-height: 25px;
}

.composition-settings__option-checkbox {
  min-width: 40%;
}

.composition-settings__button {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
}

.composition-settings__duration-unit {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
}

.radio-button__duration {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.min-width {
  min-width: 25px;
}
