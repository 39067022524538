.transition-group__tools{
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.active {

}

