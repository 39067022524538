.paragraphs-cards {
  width: 100%;
  max-width: 1280px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #232323;
  position: fixed;
  box-sizing: border-box;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.paragraphs-card {
  width: 100%;
  height: 30px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  background: #232323;
  border: solid 1px #1d1d1d;
  color: rgba(197, 197, 197, 0.5);
}

.button-icon {
  justify-content: center;
}

.button-card__paragraph {
  margin: 0;
}

.button-icon:hover {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

.paragraphs-card__disabled {
  opacity: 0.5;
  pointer-events: none; /* Делает элемент неактивным для событий мыши */
}
