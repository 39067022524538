.navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  margin: 0;
  margin-top: 50px;
  position: fixed;
  z-index: 1;
  background-color: #232323;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}

.navigation__item {
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  padding: 10px 0;
  color: #c5c5c5;
  min-width: 25px;
  cursor: pointer;
  justify-content: center;
  font-weight: 900;
  background-color: #161616;
  border: solid 1px #1d1d1d;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.navigation__item:hover {
  background: #1f1f1f;
  color: #2d8ceb;
  border-bottom: solid 1px #2d8ceb;
}

.navigation__item--active {
  background: #1f1f1f;
  color: #2d8ceb;
  border-bottom: solid 1px #2d8ceb;
}
