.render-group__dropdown {
  display: flex;
  flex-direction: column;
  color: #c5c5c5;
  background-color: #161616;
  width: 100%;
}

.render-group__dropdown select {
  cursor: pointer;
  color: #c5c5c5;
  padding: 5px;
  width: 100%;
  border: solid 1px #232323;
  transition: all 0.3s ease-in-out;
  background-color: #161616;
  appearance: none; /* Убирает стандартные стили браузера для <select> */
}

.render-group__dropdown-short select {
  width: 20%;
}

.render-group__dropdown select:hover {
  border: solid 1px #3456f3;
  outline: none;
}

.render-group__dropdown select:focus {
  border: solid 1px #3456f3;
  outline: none;
}

.render-group__dropdown-text {
  display: flex;
  justify-content: space-between;
  color: #c5c5c5;
  white-space: nowrap;
  background-color: #232323;
  padding: 5px 5px 5px 0;
  font-size: 0.9em;
}

.render-group__dropdown-icon {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}

.render-group__dropdown-icon:hover {
  color:#2d8ceb;
  opacity: 1;
}

.template__popup {
  display: flex;
  flex-direction: column;
  min-width: 170px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1d1d1d;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.close-button {
  /* Поместите кнопку "X" в правом верхнем углу popup с помощью абсолютного позиционирования */
  position: absolute;
  top: 3px;
  right: 7px;
  cursor: pointer;
  font-size: 15px;
  z-index: 1001;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}

.close-button:hover {
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.render-group--hidden {
  display: none;
}

.template__group{
  display: flex;
  align-items: center;
  gap:5px;
}

