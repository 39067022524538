/* Параметры */
.param-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.param-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #c5c5c5;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.param-header:hover {
  opacity: 0.7;
}

.icon {
}

.param-name {
  font-size: 14px;
}

.param-info {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}

.param-description {
  transition: all 0.4s ease-in-out;
  opacity: 0.8;
}

.param-image {
  max-width: 50%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
}

.fixed-container {
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.fixed-container:hover {
  opacity: 0.7;
}

.param-script:hover {
  cursor: pointer;
  opacity: 0.5;
}

.span-opacity {
  opacity: 0.5;
}
