/* Блок */
.render-group {
  padding: 10px;
}

.render-group__checkbox {
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  /* padding: 5px 10px 10px 0; */
}

.render-group__checkbox:hover {
  opacity: 0.8;
}

.hidden-checkbox {
  display: none;
}

label {
  display: flex;
  align-items: center; /* Это будет выравнивать элементы внутри label по вертикали */
}

/* Если вы хотите, чтобы текст "Change Name" был другого размера, вы можете выделить его в отдельный <span> и задать ему соответствующий стиль. Например: */

.label-text {
  cursor: pointer;
  font-size: 12px; /* или любой другой размер, который вам подходит */
}

.custom-checkbox {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #c5c5c5; /* Этот цвет будет применен когда чекбокс выключен */
  border-radius: 3px;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.hidden-checkbox:checked + label .custom-checkbox {
  background-color: #2d8ceb;
}

/* Если хотите добавить "галочку" в чекбокс: */
.hidden-checkbox:checked + label .custom-checkbox::before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.render-group__tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
}

.render-group__button {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.render-group__button-text {
  max-width: 100%;
  opacity: 0.5;
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Модификаторы */
.render-group__button:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #2d8ceb;
}

.render-group--hidden {
  display: none;
}

.render-group__toggle.active {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

.render-group__checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 0 0 10px 0;
  width: 100%;
}

.set-button {
  margin-bottom: 10px;
}

.set-button-active {
  color: #2d8ceb;
  fill: #2d8ceb;
}

.hidden {
  display: none;
}
