.user-profile {
  display: flex;
  position: fixed;
  align-items: center; /* centers children horizontally */
  flex-direction: column;
  gap: 15px;
  left: 50%;
  transform: translateX(-50%); /* centers the element horizontally */
  width: 100%;
  max-width: 1280px;
  height: 100vh;
  background-color: #161616;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.user-profile_visible {
  opacity: 1;
  pointer-events: auto;
}

.user-profile__avatar {
  margin-top: 30px;
  display: flex;
  align-items: center; /* выравнивание содержимого по вертикали */
  justify-content: center; /* выравнивание содержимого по горизонтали */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #232323;
  color: white; /* добавим белый цвет для буквы, чтобы она была видна на темном фоне */
  font-size: 50px; /* увеличим размер шрифта для буквы "A" */
}

.user-profile__bio {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 250px;
  justify-content: center;
  align-items: center; /* centers children horizontally */
  margin: 0;
  margin-top: 25px;
  padding: 0;
}

.user-profile__name {
  font-size: 25px;
}

.user-profile__login {
  opacity: 0.7;
}

.user-profile__subscription {
  background-color: #232323;
  padding: 10px 15px;
  border-radius: 25px;
}

.user-profile__logout {
  cursor: pointer; /* чтобы пользователь видел, что это кликабельный элемент */
  background-color: #f44336; /* красный цвет, например, для кнопки выхода */
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  margin-top: 16px; /* отступ сверху для отделения от других элементов */
  transition: background-color 0.3s;
  transition: opacity 0.5s ease;

  /* Хорошо бы добавить эффекты наведения и нажатия */
}

.user-profile__logout:hover {
  opacity: 0.9;
}

.user-profile__request {
  cursor: pointer; /* чтобы пользователь видел, что это кликабельный элемент */
  background-color: #007bff; /* красный цвет, например, для кнопки выхода */
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  margin-top: 16px; /* отступ сверху для отделения от других элементов */
  transition: background-color 0.3s;
  transition: opacity 0.5s ease;

  /* Хорошо бы добавить эффекты наведения и нажатия */
}

.user-profile__request:hover {
  opacity: 0.9;
}

.user-profile__logout:active {
  background-color: #d32f2f;
}

.user-profile__form{
 display: flex;
 flex-direction: column;
 gap:5px
}

.user-profile__input{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-profile__label{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
