.header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;
  height: 50px;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #232323;
  padding: 0 10px;
  z-index: 1;
}

.header__controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}

.header__user_group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.header__user {
  font-size: 20px;
}

.header__logo {
  width: 90%;
}

.header__profile {
  cursor: pointer;
  font-size: 15px;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
}

.header__profile:hover {
  opacity: 1;
}
