.background__color-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
}

.background__color-square {
  width: 100%;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out;
}

.background__color-square:hover {
  opacity: 0.7;
}

.black {
  background-color: #000000;
}

.gray {
  background-color: #808080;
}

.white {
  background-color: #ffffff;
}
