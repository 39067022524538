.resolution-tools {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(120px, 1fr)
  ); /* Минимальная ширина для кнопки установлена в 180px. */
  gap: 10px;
  padding: 10px;
}

.resolution-tools__button {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resolution-tools__button span {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity:0.5;
}

.resolution-tools__button:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #2d8ceb;
}

.resolution-tools__button span {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
}

.resolution-toggle.active {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

.resolution-tools.hidden {
  display: none;
}

.resolution-tools__checkbox {
  grid-column: span 1;   /* Занимает только одну колонку */
  align-self: center;   /* Выравнивает по вертикали по центру */
  padding: 0;           /* Удалить или уменьшить отступы, если необходимо */
}


