.shape__input {
  width: 50px;
}

.color-picker-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.color-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.color-picker-buttons {
  display: flex;
  justify-content: space-between;
  gap: 2px;
}

.color-picker-button {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  background-color: rgb(27, 27, 27);
  padding: 10px 5px;
  transition: all 0.4s ease-in-out;
  border-radius: 2px;
  color: #c5c5c5;
  border-bottom: solid 1px #c5c5c5;
}

.color-picker-button:hover {
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
}
